import * as React from "react"
import MainLayout from '../../layouts/main'
import {useContext} from "react";
import GlobalContext from "../../context/GlobalContext";
import ContributiFiscali from "../home/contributi-fiscali";
import Help from "../home/help";
import Partners from "../home/partners";
import SeoComponent from "../../layouts/partials/SeoComponent";


const Cookie = ({pageContext}) => {
    const gContext = useContext(GlobalContext);
    return (
        <MainLayout pageContext={pageContext}>
            <div className="max-w-[1400px] mx-auto py-16 px-4 sm:px-6 lg:px-8 relative w-full">
                <h2 className="title-section text-center flex flex-col items-center justify-center">{gContext.t('Informativa cookie')}</h2>
                <div className="border-y border-black my-10 py-10 w-full space-y-4 text-justify"
                     dangerouslySetInnerHTML={{ __html: gContext.t('cookie.text') }}></div>
            </div>

            <ContributiFiscali/>
            <Help/>
            <Partners/>
        </MainLayout>
    )
}

export default Cookie

export const Head = ({pageContext}) => (
    <SeoComponent pageContext={pageContext} />
)