import * as React from 'react'
import {useContext} from "react";
import GlobalContext from "../../context/GlobalContext";
import {Link} from "gatsby";

export default function ContributiFiscali() {
    const gContext = useContext(GlobalContext);
    return (
        <div className="relative w-full bg-lime py-8 sm:py-16">
            <div className="max-w-[1400px] mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-1 lg:grid-cols-12 gap-6 sm:gap-16">
                <div className="lg:col-span-5 lg:col-start-1 flex items-center justify-center">
                    <img className="mx-auto w-full pointer-events-none max-w-[520px]" width="520" height="499" src={'/ecobonus/ecobonus-full.svg'} alt="Ecobonus"/>
                </div>
                <div className="lg:col-span-7 lg:col-start-6 flex flex-col items-start text-justify sm:text-left">
                    <h2 className="title-section mb-1">{gContext.t('Contributi fiscali')}</h2>
                    <h3 className="title-section text-[#306b00] mb-6 sm:mb-10">{gContext.t('Le agevolazione sulle schermature solari')}</h3>
                    <p dangerouslySetInnerHTML={{ __html: gContext.t('ecobonus.text.1') }}></p>
                    <p dangerouslySetInnerHTML={{ __html: gContext.t('ecobonus.text.2') }}></p>
                    <p className="text-[22px] sm:text-[26px] md:text-[30px] font-bold text leading-8 text-justify sm:text-center my-10">{gContext.t('Convenienza - Semplicità - Velocità - Sostenibilità')}</p>
                    <Link to={gContext.url('contributi')} className="white-black-button w-full max-w-[250px] mx-auto sm:mx-0">{gContext.t('Scopri i vantaggi')}</Link>
                </div>
            </div>
        </div>
    )
}
